
var template= angular.module('templates', []);

var app = angular.module('nivel', []);

/*angular.element(function() {
    console.log('instantiate');
    angular.bootstrap(document.body, ['nivel']);
});*/

